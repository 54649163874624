import React, { useEffect, Suspense } from 'react';
import './App.css';
import Login from './view/Login'
import Forgot from './view/Forgot'
import Reset from './view/Reset'
import Error404 from './view/Error404'
import {
  BrowserRouter as Router,
  Switch,
  Route,

} from "react-router-dom";
import AOS from 'aos'
import 'aos/dist/aos.css'

const SKL = React.lazy(() => import('./skl'));

function App(props) {

  useEffect(() => {
    AOS.init({
      duration: 1500
    });
  }, []);

  return (
    <Router >
    <Switch>
      <Route path="/skl/" render={(props) => <Suspense fallback={<div>Loading...</div>}>
      < SKL basePath="/skl/" {...props} />
    </Suspense>
    } />
      <Route exact path="/" ><Login /></Route>
      <Route exact path="/forgot" ><Forgot /></Route>
      <Route exact path="/reset" ><Reset /></Route>
      <Route ><Error404 /></Route>
    </Switch>

  </Router>
  );
}

export default App;

